<template>
  <div class="app portal d-flex">
    <div id="portal-top" class="portal-top d-flex flex-column justify-content-center align-items-center">
      <!--<img src="https://www.cistmt.com.br/wa_images/logo_(1).png" alt="Logo">-->
      <img src="@/assets/images/logo_siges.svg" alt="Logo">
      <h1 class="portal-logo-text">SIGES</h1>

    </div>
    <div class="container flex-grow-1 pt-4 pb-4">
      <div class="cards card-aluno d-flex flex-column justify-content-between">
        <div class="d-flex justify-content-between align-items-center">
          <div class="card-aluno-foto rounded-circle d-flex justify-content-center align-items-center">
            <img v-if="infoLogado.pes_foto_url !== ''" :src="infoLogado.pes_foto_url" alt="Foto">
            <img v-else src="@/assets/images/sem_foto.png" alt="Foto">
          </div>
          <div class="card-aluno-dados d-flex flex-column justify-content-start align-items-top flex-grow-1 p-2 ml-2">          
            <div class="d-flex flex-row justify-content-between mb-1">
              <div class="d-flex flex-row">
                <div class="d-flex flex-column">
                  <small>R.A.</small>
                  <span>{{infoLogado.pes_codigo}}</span>
                </div>
                <div class="d-flex flex-column ml-3">
                  <small>Aluno</small>
                  <span>{{infoLogado.pes_nome}}</span>
                </div>
              </div>
              <div class="d-flex flex-column">
                <small>Data Nascimento</small>
                <span>{{infoLogado.pes_nascimento | data}}</span>
              </div>
            </div>
            <!--<div class="d-flex flex-row justify-content-between mb-1">
              <div class="d-flex flex-column">
                <small>Endereço</small>
                <span>{{infoLogado.pes_logradouro}}, {{infoLogado.pes_logra_numero ? 'Nº '+ infoLogado.pes_logra_numero : 'S/N'  }}, {{infoLogado.pes_bairro}}</span>
                <span>{{infoLogado.pes_cidade}}-{{infoLogado.pes_estado}}</span>
              </div>
            </div>-->
            <div class="d-flex flex-row justify-content-between">
              <div class="d-flex flex-column" v-if="infoEscola.esc_tipo !== 1">
                <small>Responsável Cobrança</small>
                <span>{{infoLogado.respcobranca ? infoLogado.respcobranca.pes_nome : infoLogado.pes_nome}}</span>
              </div>
              <div class="d-flex flex-column">
                <small>Email</small>
                <span>{{infoLogado.pes_email}}</span>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="d-flex flex-row mt-2">
          <button type="button" class="btn btn-success btn-sm" aria-label="Left Align"  @click.prevent.stop="$router.go(-1)">
            <span class="fa fa-chevron-left fa-lg" aria-hidden="true"></span> Voltar </button>        
        </div>-->
      </div>


      <router-view/>
    </div>
    <div class="portal-footer">
      <!--footer-->
      <div>
        <a href="https://www.mercatosa.com.br">Mercato</a>
        <span class="ml-1">&copy; {{moment().year()}}</span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Desenvolvido por</span>
        <a href="https://www.mercatosa.com.br">Mercato SA</a>
      </div>      
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'LayoutPortal',
  components: {
  },
  data () {
    return {
      nav: []
    }
  },
  methods: {
    
  },
  created () {
    
  },
  mounted () {
    setTimeout(
      function(){
        var ele = document.getElementById('portal-top');
        //console.log(ele);
        ele.classList.add('active');
      }
    , 1000);  
  },
  computed: {
    ...mapState('Portal',['infoLogado', 'infoEscola', 'infoEntidade'])
  },
}
</script>

<style lang="scss">  
  @import '../../assets/scss/styleportal';
</style>